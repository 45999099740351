#aktionsfinder
    display: grid
    grid-template-areas: 'neukunde events stammkunde' 'pr anzeigen email' 'website kundendienst zielgruppe'
    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: auto
    grid-gap: 1rem
    .grid--item
        text-align: center
        margin-bottom: 2rem
        cursor: pointer
        h3
            font-size: 1.2rem
            margin-bottom: 1rem
        img
            width: 150px
        
        &.neukunden
            grid-area: neukunde
        &.events
            grid-area: events
        &.stammkunde
            grid-area: stammkunde
        &.pr
            grid-area: pr
        &.anzeigen
            grid-area: anzeigen
        &.emailmarketing
            grid-area: email
        &.website
            grid-area: website
        &.kundendienst
            grid-area: kundendienst
        &.zielgruppe
            grid-area: zielgruppe
    .grid--overlay
        background: #ececec
        padding: 2rem
        grid-column: 1 / -1
        display: flex
        flex-wrap: wrap
        align-items: center
        display: none
        position: relative
        z-index: 100
        .close--overlay
            background: $grau
            color: white
            width: 1.5rem
            height: 1.5rem
            position: absolute
            right: 1rem
            top: 1rem
            font-size: 2rem
            cursor: pointer
            text-align: center
            line-height: 1.5rem
            font-weight: 100
        &.active
            display: flex
        &.neukunden
            grid-row: 1
        &.events
            grid-row: 1
        &.stammkunde
            grid-row: 1
        &.pr
            grid-row: 2
        &.anzeigen
            grid-row: 2
        &.emailmarketing
            grid-row: 2
        &.website
            grid-row: 3
        &.kundendienst
            grid-row: 3
        &.zielgruppe
            grid-row: 3

        h3
            font-size: 1.2rem
            display: block
            min-width: 100%
            margin-bottom: 1rem
        ol
            flex: 2 0 0
            min-width: 220px
            li
                list-style-type: decimal
                margin-left: 20px
                //margin-bottom: 0.5rem
        .grid--title
            width: 250px
            text-align: center
            img
                width: 150px
        .buttonOrange
            align-self: flex-end
            margin: 2rem auto 0
    @media (max-width: 760px)
        grid-template-areas: 'neukunde events' 'stammkunde pr' 'anzeigen email' 'website kundendienst' 'zielgruppe .'
        grid-template-columns: repeat(2, 1fr)
        .grid--item
            img
                width: 100px
        .grid--overlay
            &.active
                display: block
            //text-align: center
            .grid--title
                width: 100%
            ol
                margin: 1rem auto
                display: block
                max-width: 300px
            .buttonOrange
                margin: 2rem auto 0
                display: block
            &.neukunden
                grid-row: 1
            &.events
                grid-row: 1
            &.stammkunde
                grid-row: 2
            &.pr
                grid-row: 2
            &.anzeigen
                grid-row: 3
            &.emailmarketing
                grid-row: 3
            &.website
                grid-row: 4
            &.kundendienst
                grid-row: 4
            &.zielgruppe
                grid-row: 5
    @media (max-width: 460px)
        grid-template-areas: 'neukunde' 'events' 'stammkunde' 'pr' 'anzeigen' 'email' 'website' 'kundendienst' 'zielgruppe'
        grid-template-columns: repeat(1, 1fr)
        .grid--overlay
            &.neukunden
                grid-row: 1
            &.events
                grid-row: 2
            &.stammkunde
                grid-row: 3
            &.pr
                grid-row: 4
            &.anzeigen
                grid-row: 5
            &.emailmarketing
                grid-row: 6
            &.website
                grid-row: 7
            &.kundendienst
                grid-row: 8
            &.zielgruppe
                grid-row: 9

                
