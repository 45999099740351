body #usercentrics-button
    #uc-banner-modal
        .uc-banner-wrapper
            z-index: 999999999999999999!important
    #uc-central-banner-modal
        z-index: 999999999999999999!important
    #uc-banner-modal 
        #uc-main-banner
            z-index: 999999999999999999!important
    #uc-consents-info-modal
        .uc-consents-info-modal-wrapper
            z-index: 9999999999999999999!important
    #uc-consents-info-modal
        .uc-consents-info-modal-wrapper 
            .uc-modal-header
                background-color: $dunkelorange!important
                .uc-info-modal-search-bar 
                    input
                        background: white!important
                        color: $base-font-color!important
                        border: none!important
                        &::placeholder
                            color: $base-font-color!important
    .uc-powered-by-footer.uc-flex-container,
    .uc-powered-by
        display: none!important
.cc_banner-wrapper
    .cc_container
        padding: 10px
        position: fixed
        bottom: 0
        left: 0
        z-index: 9999999999999999
        background: white
        box-shadow: 0 0 20px rgba(black, 0.2)
        display: flex
        align-items: center
        justify-content: space-between
        a.cc_btn_accept_all
            order: 2
            padding: 5px 10px
            color: white
            background: $orange
            border-radius: 4px
            margin: 10px
            font-size: 0.7em
        .cc_message
            padding: 10px
            font-size: 0.7rem
            color: #aaa
            max-width: 90% 
            a
                font-size: inherit
        @media (max-width: 700px)
            flex-direction: column
            align-items: flex-start
            .cc_message
                max-width: 100%