.modal
    position: fixed
    width: 100%
    height: 100%
    top: 100vh
    opacity: 0
    left: 0
    background: rgba(black, 0.8)
    z-index: 999999
    display: flex
    justify-content: center
    align-items: center
    transition: none
    .modal--content
        padding: 40px
        position: relative
        background: white
        max-width: 400px
        margin: 0 auto
        box-shadow: 2px 2px 20px rgba(black, 0.2)
        border-bottom: 3px solid $orange
        transition: all 300ms ease
        opacity: 0
        top: 100px
        .close
            font-family: "Open Sans", sans-serif
            font-size: 0.7em
            line-height: 1
            margin: 0
            padding: 0
            position: absolute
            display: inline-block
            top: 20px
            right: 20px
            cursor: pointer
            span
                color: $orange
                font-size: 2em
                line-height: 0
                position: relative
                top: 6px
        h3
            font-size: 1.2em
        form
            input:not([type="submit"])
                border: 1px solid grey
                background: #fff
                display: block
                min-width: 280px
                padding: 5px 10px
                height: auto
                margin-bottom: 10px
                font-size: 0.8em
                font-family: "Open Sans", sans-serif
                outline: none
                &:focus
                    border-color: $orange
            input[type="submit"], .buttonOrange
                //all: unset
                border: none
                appearance: none
                font-weight: 400
                text-transform: uppercase
                font-size: 0.8em
                color: white!important
                padding: 5px 10px
                background: $orange
                cursor: pointer
                letter-spacing: 0.05em
                transition: all 200ms ease
                &:hover
                    background: lighten($orange, 10%)
                    box-shadow: 1px 1px 5px rgba(black, 0.15)
            .buttonOrange
                margin-left: 1rem
                display: inline-block
            label
                font-family: 'Open Sans', sans-serif
                font-size: 0.8rem
                line-height: 1.4
                margin: 0 0 0.5rem 0
                display: inline-block
                input[type="checkbox"]
                    display: inline-block
                    min-width: 20px
                    position: absolute
                    left: 0
                    top: 5px
                h3
                    display: inline-block
                    margin: 0
                    margin-left: 25px
                    font-size: 0.9rem
                    color: $orange
            select
                width: 100%
                margin: 1rem 0
                font-family: 'Open Sans', sans-serif
                font-size: 0.8rem
                option
                    padding: 1rem
                *
                    font-family: 'Open Sans', sans-serif
                    font-size: 0.8rem

    &.active
        top: 0
        opacity: 1
        .modal--content
            opacity: 1
            top: 0
#error-message, #error-message-reset
    font-family: "Open Sans", sans-serif
    font-size: 0.725em
    color: orangered
    max-width: 100%
    margin-top: 20px
.reset
    diplay: block
    margin: 15px 0
    font-size: 0.725em
    span
        font-family: inherit
        font-size: inherit
        color: #0066ff
        cursor: pointer
.reset-confirmation
    font-family: "Open Sans", sans-serif
    font-size: 0.725em
    color: #0066ff!important
    max-width: 100%
    margin-top: 20px
    display: block
    text-align: center


