#seminar-content
	display: flex
	flex-wrap: nowrap
	#kategorie
		flex: 1 0 auto
		width: auto

	>.seminar--content
		flex: 1 0 80%
		@media (min-width: 769px)
			margin-left: 100px
		.seminar--row
			max-width: 100%!important
		
.veranstaltungen
	.veranstaltungenImg1
		background-image: url(../img/aktuelles3.jpg)!important
		background-position: center
	.veranstaltungenImg2
		background-image: url(../img/veranstaltungen/workshops.jpg)!important
		background-position: center
	.bildtitel
		display: block
	.seminar--row
		max-width: 900px
		margin: 20px auto 60px
		display: flex
		flex-wrap: wrap
		align-items: flex-start
		
		.seminar--date
			display: inline-block
			padding: 16px 10px
			border: 1px solid lightgrey
			width: 80px
			background: white
			box-shadow: 2px 2px 5px rgba(black, .15)
			margin-right: 20px
			position: relative
			overflow: hidden
			@media (max-width: 600px)
				padding: 10px 5px
				width: 60px	
				margin-right: 10px	   

			.tag
				font-size: 2.5em
   
				font-weight: 600
				display: block
				text-align: center
				line-height: 1
				color: $orange
			.monat
				letter-spacing: 3px
				text-align: center
				display: block
				color: $grau
		.seminar--content
			
			flex: 1 0 0 
			border-left: 1px solid $orange
			padding-left: 20px
			h4
				color: $grau
				line-height: 1
				margin: 0 0 10px
				display: inline-block
				padding: 10px
				background: lighten($grau, 50%)
				font-size: 0.8em
				font-family: 'Open Sans', sans-serif
			h2
				font-size: 1.5em
				text-align: left
			@media (max-width: 600px)
				padding-left: 10px
				h2
					font-size: 1.2em
				h4
					font-size: 0.7em

			

.impressionen--wrapper
	margin: 40px auto
	// align-items: flex-start
	justify-content: flex-start!important
	flex-wrap: wrap

	.impressionen
		margin: 10px
		min-width: 150px
		height: auto
		flex: 1 0 30%
		overflow: hidden
		@media (min-width: 741px)
			max-width: calc(33.33% - 20px)
		img
			width: 100%
			height: 100%
			object-fit: cover
			transition: all 700ms ease
			transform-origin: center
		&:hover
			img
				transform: scale(1.1)