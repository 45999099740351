.werbemittel
    background: #fcfcfc
    border: 1px solid lighten($grau, 45%)
    margin-bottom: 20px
    .col-1
        // margin-right: 20px
        flex: auto
        @media (min-width: 640px)
            max-width: 300px
        img
            display: block
            max-width: 40vw
    .col-3
        padding: 20px 30px
        min-width: 220px
    @media (max-width: 800px)
        .col-3
            padding: 20px
       
    @media (max-width: 500px)
        display: block
        .col-1
            margin: 0
            img
                width: 100%
                max-width: 100%
.hinweis
    font-family: 'Open Sans', sans-serif
    display: inline-block
    padding: 5px 10px
    background: lighten($grau, 45%)
    text-transform: uppercase
    border-radius: 4px
    margin-top: 20px
    font-size: 14px
    color: $grau