h1
    padding: 0 40px
    margin-top: 40px
#news--content, #lieferanten-news--content
    br
        margin-bottom: 1rem

    padding: 40px

    .news
        width: 100%
        margin-bottom: 40px
        .news--content
            width: 100%
            display: flex
            flex-wrap: wrap
            .news--content--image
                max-width: 300px
                width: 100%
                height: 100%
                margin: 0 20px 10px 0
            .news--content--text
                max-width: 900px
                flex: 1 0 0    
                *:not(h3):not(.buttonOrange)
                    color: $grau 
                    font-family: 'Open Sans', sans-serif
                    font-size: 0.825rem 
                button
                    display: block
                    margin: 20px 0 0 0          
                h3
                    line-height: 1.2
                    margin-bottom: 1rem
                    margin-top: 0
                    min-width: 250px
                sup
                    font-size: 70%!important
                li
                    margin-bottom: 20px
                p
                    font-size: 0.825em
                .clearfix
                    clear: both
            @media (max-width: 660px)
                .news--content--image
                    max-width: 100%
                    float: none


#news--start
    display: flex
    flex-wrap: wrap
    justify-content: center

.newsContent
    .more, .read-more
        font-family: 'Open Sans',sans-serif
        color: $grau
        font-size: inherit
    .read-more
        display: none
    .buttonOrange
        outline: none
        display: inline-block
        margin-top: 20px
    &.open
        .more
            display: none
        .read-more
            display: inline