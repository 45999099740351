.header
	//margin-top: 130px
	height: 45vw
	max-height: 600px
	width: 100%
	overflow: hidden
	background-size: cover
	display: flex
	flex-wrap: nowrap
	min-height: 340px
	&.partner
		max-height: 400px

	.header--image
		background: url("../img/header1.jpg") top no-repeat
		background-size: cover
	.glashersteller
		background: url("../img/industriepartner/brillenglas.jpg") left center no-repeat
		background-size: contain
		@media (max-width: 740px)
			background-position: top center!important
			background-size: cover!important
	.fassungshersteller
		background: url("../img/header-fassungen.jpg") top center no-repeat
		background-size: cover
		@media (max-width: 740px)
			background-position: left 10% center
	.kontaktlinsenhersteller
		background: url("../img/industriepartner/kontaktlinse.jpg") left center no-repeat
		background-size: contain
		@media (max-width: 740px)
			background-position: center!important
	.geraete
		background: url("../img/industriepartner/geraete.jpg") left center no-repeat
		background-size: contain
		@media (max-width: 740px)
			background-position: center!important
	.seminare
		background: url("../img/veranstaltungen/workshops.jpg") center no-repeat
		background-size: contain
		@media (max-width: 740px)
			background-position: center!important
	.roundtable
		background: url("../img/roundtable/img_roundtable.png") left 10% center no-repeat, url("../img/roundtable/bg_roundtable.jpg") center no-repeat
		background-size: 50%, cover
		@media (max-width: 740px)
			background-size: 60%, cover!important
			background-position: top 30% center, center!important
	.stoldt
		background: url("../img/header_klassischeWerbemittel.jpg") left center no-repeat, #a1c6cf
		background-size: cover
		@media (max-width: 740px)
			background-position: left 20% center
	.werbung
		background: url("../img/header_werbemittel.jpg") center no-repeat, #a1c6cf
		background-size: cover
		@media (max-width: 740px)
			background-position: left 10% center
	.peters
		background: url("../img/header-beratung.jpg") left center no-repeat, #f7f4ef
		background-size: cover
		@media (max-width: 740px)
			background-position: 15%!important
	.beratung
		background: url("../img/header-service.jpg") left center no-repeat, #f7f4ef
		background-size: cover
		@media (max-width: 740px)
			background-position: 15%!important
	h2, h1
		margin-top: 0
		padding: 0
		color: white
		font-size: 1.6em!important

		
	.slide
		min-width: 100%
		height: 100%
		transition: left ease 1s
		display: flex
		justify-content: flex-end
		overflow: hidden
		background-position: left center
		//background-size: contain
		&.slide--1
			background: url("../img/header1.jpg") top no-repeat
			background-size: cover
		.slide--content
			position: relative
			height: 100%
			padding: 40px
			//background: #000
			width: 45%
			min-width: 250px
			display: flex
			align-items: center
			justify-content: center
			z-index: 1
			&:before
				content: ""
				width: 200%
				height: 200%
				position: absolute
				left: 0			
				top: -30%
				background: rgba($orange, 1)
				transform: rotate(17deg)
			.slide--content--text
				max-width: 350px
				@media (max-width: 740px)
					max-width: 100%
				span, strong, p, b, a:not(.button)
					color: white
					font-family: 'Open Sans', sans-serif
				h1, h2, h3, h4, h5, h6
					color: white
				p
					color: white
					font-family: 'Open Sans', sans-serif
					b
						color: white
						font-family: 'Open Sans', sans-serif
						font-weight: 700
						margin-bottom: 20px
						display: block
				em
					color: white
					display: block
					font-size: 1em
				span 
					color: white
					font-family: 'Open Sans', sans-serif
					font-size: 0.8em
					font-style: italic
		@media (max-width: 740px)
			background-size: cover
			background-position: left center
			.slide--content
				padding: 10px 20px
				width: 100%
				height: auto
				text-align: center
				position: absolute
				bottom: 0
				br
					line-height: 0
					display: none
				b,p, em
					margin-bottom: 10px!important
				&:before
					width: 100%
					height: 100%
					transform: none
					top: 0
				h1, h2
					font-size: 1.4em
					margin: 0
					padding: 0
					text-align: center


			

	img
		width: 100%
	.soft-row
		@media (max-width: 740px)
			display: block
		.col-1
			flex: 2 0 0
			min-width: 300px
			height: 45vw
		.col-3
			flex: 1 0 0
			border-top: 600px solid transparent
			border-right: 183px solid $orange
			opacity: 0.9
			max-width: 200px
			min-width: 190px
			@media (max-width: 740px)
				display: none
		.col-2
			flex: 2 0 0
			background: $orange
			opacity: 0.9
			height: 45vw
			max-height: 600px
			display: flex
			justify-content: center
			align-items: center
			margin: 0 auto 
			padding: 20px
			@media (max-width: 740px)
				height: auto
	   
				
			@media (max-width: 966px)
				padding: 20px 0
				.headerText
					padding: 0 20px
			// @media (max-width: 750px)
			//	display: block
.blog
	background: url("../img/blog/blog.jpg") center no-repeat
	background-size: cover

.ueberuns
	background: url("../img/Optics_3er_Team0018.jpg") left bottom no-repeat
	background-size: cover
	@media (max-width: 740px)
		background-position: left 10% center
.tools
	background: url("../img/tools/header.jpg") left top no-repeat
	background-size: contain
@media (max-width: 740px)
	#header--slider--content			  
		.slide
			background-position: top center!important
			background-size: cover!important

.slanted__header
	display: grid
	grid-template-areas: 'image' 'content'
	grid-template-columns: 1fr
	grid-template-rows: 50vw auto
	@media (min-width: 741px)
		grid-template-areas: 'image content'
		grid-template-columns: 5fr 4fr
		grid-template-rows: auto
		height: 45vw
		max-height: 400px
	.header__image
		@media (min-width: 741px)
			height: 100%
		background-size: cover
		background-position: center
		background-color: $orange
		grid-area: image
	.header__content
		grid-area: content
		background: $orange
		padding: 20px 20px 20px 0
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		color: white!important
		position: relative
		h1, h2, h3
			line-height: 1.2
			font-size: 1.6em
		p, b
			font-size: 0.825rem
			font-family: 'Open Sans', sans-serif
		b
			font-weight: bold
		em
			font-size: 0.8rem
			margin-top: 20px
			display: block
		@media (min-width: 741px)
			&:before
				content: ""
				position: absolute
				width: 50%
				height: 100%
				left: 0
				top: 0
				transform-origin: top left
				background: inherit
				transform: skew(-17deg)
				z-index: 0
		*
			color: white!important
			position: relative
			z-index: 1
			max-width: 350px
