#blog, #seminar-content
    .soft-row
        flex-wrap: nowrap
    #blog-select
        display: none
        @media (max-width: 768px)
            display: block
            margin-bottom: 20px
            padding-bottom: 20px
            border-bottom: 1px solid lighten($grau, 45%)
            p
                display: inline-block
                margin-right: 10px
                font-weight: bold
                font-size: 1em
            #blog--mobile
                outline: none
                font-family: 'Open Sans', sans-serif
    #kategorie
      width: 300px
      
      @media (max-width: 768px)
          display: none
    
      ul
        margin: 0
        list-style: none
        height: auto
        li
          margin: 0
          list-style: none
          border-bottom: 1px solid rgba($grau, 0.2)
          color: $grau
          a
            color: $grau
            font-size: 1em
            line-height: 1.5
            display: block
            padding: 10px 0
            cursor: pointer
            &:hover, &.active
              color: $grau
              padding: 10px 2px 10px 10px
            &.active
                color: $orange
      h3 
        text-align: left
        font-size: 1.7rem
    .blogContent
        overflow: hidden
        position: relative
        h2
            text-align: left
        h3 
            text-align: left
        dd
            text-align: left
        p, strong
            text-align: left
        #blog--content
            .eintrag
                border-bottom: 1px solid lighten($grau, 45%)
                margin-bottom: 40px
            strong, b
                color: $grau
                font-size: 1.2em
                padding-top: 20px!important
                display: block
                font-weight: 500
                text-transform: uppercase
            p
                margin-bottom: 20px
            a
                font-family: 'Open Sans', sans-serif
                font-weight: bold
            li 
                p 
                    font-size: .825rem

            ol 
                li
                    list-style: decimal
                    margin-bottom: 10px
                    margin-left: 15px

        .row-inner
            margin-left: 100px
            text-align: left
            @media (max-width: 768px)
                margin-left: 0px
.active
    dt 
        &:before
            display: none
        &:hover 
            background: rgba(206,206,206,0.5)
    
    dd
        height: 100%!important
        overflow: visible
        padding: 20px
        


.blog__box
    margin: 40px 0 60px 0
    .blog__box--title
        background: lighten($grau,45%)
        padding: 8px 15px
        border-radius: 4px 4px 0 0
        h4
            color: $grau
            margin: 0
            font-size: 1em
            font-weight: normal
    .blog__box--content
        padding: 15px
        border: 1px solid lighten($grau,45%)
        border-radius: 0 0 4px 4px
        ul
            li
                margin-bottom: 5px


.blog__image
    width: 300px
    float: right
    margin-left: 2rem
    text-align: center
    z-index: 1
    img
        width: 100%
        max-width: 50vw
        margin: 0 auto
    .fa-search-plus
        position: absolute
        right: 0
        bottom: 0
        font-size: 1.5rem
        color: white
        padding: 10px
        background: grey
    @media (max-width: 480px)
        float: none!important
        margin: 10px auto
        width: 100%
        img 
            max-width: 100%
.video 
    max-width: 50vw
    video 
        width: 100%
    

.background--image
    height: 300px
    background-size: cover
    background-position: top left
    border: 1px solid #efefef
    cursor: pointer
    z-index: 10
    position: relative
    &:after
        content: ""

#modal
    position: fixed
    z-index: 99999999999999999999999999999999
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow-y: auto 
    background: #ececec
    display: none
    &.active
        display: block
    .modal__content
        max-width: 800px
        width: 100%
        margin: 0 auto
        #close
            text-align: right
            width: 100%
            display: block
            font-size: 3rem
            cursor: pointer
            display: flex
            flex-direction: row
            align-items: center
            justify-content: flex-end
            color: #707070
            p 
                font-size: 13px
                display: inline-block
                margin-right: 5px
        img
            width: 100%

//Tabellen
.table-wrapper
    position: relative
    margin-bottom: 20px
    overflow: hidden
    display: flex
    
    .scrollable 
        @media (max-width: 1200px)
            overflow: scroll
            overflow-y: hidden
    .fixed
        th 
            min-width: 150px
            
            &:first-child
                background: transparent
                max-width: 100%
        td 
            &:first-child
                text-align: left
                font-family: Oswald,sans-serif
    table 
        margin-bottom: 20px
        th 
            max-width: 150px
            padding: 10px 5px 
            font-family: Oswald,sans-serif
            font-size: 0.8rem
            background: $orange
            color: white
            height: 60px
            min-width: 100px
            text-align: center
           
            
            
        td 
            text-align: center
            padding: 0 5px 
            font-weight: normal
            font-family: 'Open Sans',sans-serif
            font-size: 0.8rem
            height: 30px
            @media (max-width: 1020px)
                height: 40px
            @media (max-width: 750px)
                height: 50px
        tr
            &:nth-child(odd)
                background: #e3e3e3
            &:first-child
                background: transparent