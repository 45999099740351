footer 
    background: $dunkelorange
    width: 100%
    height: auto
    padding-top: 5px
    padding-bottom: 10px
    @media (max-width: 960px)
        //padding-bottom: 120px
    @media (max-width: 480px)
        //padding-bottom: 135px
        align-items: center 
    .footerContent
        align-items: center
        max-width: 1200px
        margin: 0 auto
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        @media (max-width: 1200px)
            margin: 0 20px
        #footer
            @media (max-width: 472px)
                align-items: center
                margin: 7px auto 0 
            p
                color: white
                font-size: 0.8em
        #footerpunkte
            @media (max-width: 472px)
                align-items: center
                margin: 0 auto
            a
                margin-left: 30px
                color: white
                font-size: 0.8em
