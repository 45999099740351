/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  
  /* open-sans-700 - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/open-sans-v29-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  
  /* open-sans-800 - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/open-sans-v29-latin-800.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-800.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  
  /* open-sans-italic - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/open-sans-v29-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-italic.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  /* oswald-regular - latin */
@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/oswald-v48-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/oswald-v48-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/oswald-v48-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/oswald-v48-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/oswald-v48-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/oswald-v48-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
  }
  
  /* oswald-600 - latin */
  @font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/oswald-v48-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/oswald-v48-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/oswald-v48-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/oswald-v48-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/oswald-v48-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/oswald-v48-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
  }
  
  /* oswald-700 - latin */
  @font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/oswald-v48-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/oswald-v48-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/oswald-v48-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/oswald-v48-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/oswald-v48-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/oswald-v48-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
  }
  
  