#anfrage
    text-align: left

form
    label[for="daten"]
        font-size: 0.8em
    input[type="text"]
        border: none
        background: #f1f1f1
        width: 100% 
        height: 40px
    input[type="tel"]
        border: none
        background: #f1f1f1
        width: 100% 
        height: 40px
    input[type="email"]
        border: none
        background: #f1f1f1
        width: 100% 
        height: 40px
    label
        margin-left: 10px
    input#url
        position: absolute
        left: 2000px
        opacity: 0