*
    box-sizing: border-box
    margin: 0
    padding: 0
    list-style: none
    text-decoration: none
    -webkit-font-smoothing: antialiased
    line-height: 1.6
    color: #333
    //transition: all 300ms ease
    position: relative
body
    padding-top: 140px
    @media (max-width: 1250px)
        padding-top: 115px