#ueberuns
    .soft-row
        .col-1
            min-width: 300px
            margin-bottom: 20px
        h3 
            text-align: left

.mitglieder
    .col-1
        //min-width: 250px!important
        width: 100%
            
        .ueberunsImg1
            background-image: url(../img/ueberuns/michael-stoldt.jpg)!important
            background-position: center
        .ueberunsImg2
            background-image: url(../img/ueberuns/birgit-schelbergen.jpg)!important
            background-position: center
        .ueberunsImg3
            background-image: url(../img/ueberuns/ralf-mackensen.jpg)!important
            background-position: center
        .ueberunsImg4
            background-image: url(../img/ueberuns/4.jpg)!important
            background-position: center
        .bildtitel
            display: block
            h4
                font-size: 1em
.grid
    display: flex
    width: 100%
    flex-wrap: wrap
    @media (max-width: 570px)
        display: block
        .box
            background-position: top center!important


    .col-1
        flex: 1 0 21%
        @media (min-width: 971px)
            margin-right: 1rem
        @media (max-width: 970px)
            flex: 1 0 46%
            margin-right: 1rem
            margin-bottom: 1rem
            &:nth-child(even)
                margin-right: 0
        @media (max-width: 570px)
            margin-bottom: 1rem
            margin-right: 0

        &:first-child
            grid-area: first 
        &:last-child
            margin-right: 0
