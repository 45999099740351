.werbeportfolio
    h3 
        color: $orange
        text-align: left
    h2
        text-align: left
    .toggle
        .toggle--content
            margin-bottom: 40px
            display: flex
            flex-wrap: wrap
            align-items: center
            justify-content: flex-start
            h3
                margin-bottom: 10px
                &:nth-of-type(2)
                    margin-top: 40px
            .toggle--content--icon
                width: 100px
                display: inline-block
                margin: 0 20px 10px 0
            dt
                margin-top: 5px
            dd, dt
                min-width: 100%
                width: 100%
                display: block
                font-size: 0.825em
            .toggle--content-desc
                &.full
                    min-width: 100%
                flex: 1 0 0
                ul
                    li
                        font-size: 0.825em
                
        