#willkommen
	padding-top: 7%
	background: white
	img 
		height: auto
		max-width: 400px

	#teambild
		background: url(../img/team.jpg) left 35% center no-repeat
		background-size: cover
		flex: 2 0 0
		position: relative
		overflow: hidden
		min-height: 200px
		@media (max-width: 950px)
			background-position: top center
		
		&:after
			content: ""
			position: absolute
			height: 200%
			width: 50%
			transform: rotate(17deg)
			background: #fff
			right: 85%
			top: -50%
			@media (max-width: 950px)
				content: none
	#schraege
		.col-3
			background: white
	.col-1
		max-width: 322px
		flex: 1 0 0
	.dreieck
		position: absolute
		left: 0
		top: 0
		border-bottom: 480px solid transparent
		border-left: 183px solid white			
		height: 100%

		


#onlineExperten
	text-align: center
	max-width: 900px
	margin: 0 auto 100px
	.einleitung
		margin-bottom: 0
	#online-experten--data
		margin-bottom: 40px
		b
			margin: 20px auto 40px
			display: block
			font-size: 1.1em
	.ueberschrift
		display: flex
		justify-content: center
		align-items: center

		img 
			width: 15%
			height: 15%
			display: block
		h2
			margin: 0!important
	.tools
		background-image: url(../img/online-tool.jpg)
		background-position: left top
	.blog 
		background-image: url(../img/marketing_blog.jpg)
		background-position: right
	.soft-row
		.col-2
			min-width: 300px
			margin-right: 10px
			margin-bottom: 20px
			@media (max-width: 654px)
				margin-right: 0px
		.col-1
			img
				width: 100%
				max-width: 400px
			min-width: 300px
			margin-left: 10px
			@media (max-width: 674px)
				margin-left: 0px
   
.aktuelles
	// padding-left: 20px
	// padding-right: 20px
	.soft-row
		justify-content: center
		@media (max-width: 740px)
			flex-direction: column
			align-items: center
			text-align: center
		#news--lieferanten
			flex: 1 0 0
			width: 100%
			@media (max-width: 740px)
				display: block

		#news--start
			width: 100%
			flex: 2 0 0
			@media (max-width: 740px)
				display: block

		a
			height: auto
			margin: 0 10px 30px
			min-width: 160px
			display: block
			flex: 1 0 30%
			@media (max-width: 1170px)
				max-width: 600px
				align-items: center
				  
				.box
					height: 45vw
					min-width: 200px
					width: 100%
					max-height: 30vw
			@media (max-width: 740px)
				max-width: 400px
				margin: 10px auto
				width: 100%
				
				.box
					margin-bottom: 20px

		.aktuellesImg1
			background-image: url(../img/aktuelles1.jpg)		 
		.aktuellesImg2
			background-image: url(../img/aktuelles2.jpg)
		.aktuellesImg3
			background-image: url(../img/aktuelles3.jpg)
  
		

#untererBereich
	background: $orange
	margin-bottom: 0px
	padding: 50px 0
	b 
		color: white
		font-weight: 700
	p 
		color: white
	.content
		max-width: 1200px
		margin: 0 auto
		@media (max-width: 770px)
			display: block
				
		.col-1
			padding: 0 50px
			min-width: 200px
			@media (max-width: 770px)
				padding: 20px
			
		.borderWhite
			border-left: 1px solid white
			border-right: 1px solid white
			@media (max-width: 770px)
				border: none
		.ausnahme
			padding: 0 50px 0 0
			@media (max-width: 1200px)
				padding: 0 20px 
			@media (max-width: 770px)
				padding: 20px

.news--grid
	display: grid
	grid-gap: 20px
	grid-template-areas: 'g1 g2 g3'
	grid-template-rows: auto
	grid-template-columns: 1fr 1fr 1fr
	@media (max-width: 990px)
		grid-template-areas: 'g1 g2' 'g3 .'
		grid-template-columns: 1fr 1fr
		grid-template-rows: auto auto

	> a
		&:nth-child(1)
			grid-area: g1
		&:nth-child(2)
			grid-area: g2
		&:nth-child(3)
			grid-area: g3