.header
    h1
        color: white
        font-size: 2em
    h2 
        font-size: 2em
#online-tools
    text-align: center
    .col-1
        margin: 20px
        flex: 1 0 30%
        min-width: 280px
    h2 
        font-size: 2em!important
    h3 
        font-size: 1em
    .bildComp
        width: 300px
        align-items: center
        margin: 0 auto
        perspective: 800px
        img 
            max-width: 100%
            width: auto
            height: auto
            display: block
            margin: 0
            padding: 0
        .screenBg
            transform: rotateX(-45deg)
            transform-origin: center bottom
            transition: all 0.5s ease-out
            position: relative
            margin: 0
            padding: 0
            &:hover
                transform: rotateX(0deg)
    .diagramm
        max-width: 845px
        width: 100%
        margin: 0 auto
        .zeile
            display: flex
            p 
                flex: 1 0 0
                text-align: right
                margin-right: 10px
                min-width: 150px
                max-width: 200px
                @media (max-width: 500px)
                    font-size: 0.8em
            span 
                flex: 1 0 0
                max-width: 30px
            .balken
                flex: 2 0 0
                width: 100%
                height: 20px
                max-width: 600px
            #internet
                width: 0
                background: $grau
                height: 20px
            #fachgeschaefte
                width: 0
                background: #8c8b8b
                height: 20px
            #freunde
                width: 0
                background: #ababab
                height: 20px
            #zeitungen
                width: 0
                background: #cecece
                height: 20px
            #messen
                width: 0
                background: #ffc470
                height: 20px
            #prospekte
                width: 0
                background: #fdb23b
                height: 20px
            #buecher
                width: 0
                background: $orange
                height: 20px
            #radio 
                width: 0
                background: $dunkelorange
                height: 20px
    .toolsButton
        padding: 15px 40px