// Leiste über dem Menü
    
#kontaktLeiste 
    width: 100%
    background: $orange
    height: 30px
    position: fixed
    z-index: 999999999999999999
    top: 0
    .kontaktContent
        height: 100%
        text-transform: uppercase
        justify-content: space-between
        display: flex
        flex-wrap: wrap
        max-width: 1200px
        margin: 0 auto
        @media (max-width: 1200px)
            margin-right: 20px
        a,p
            color: white
            font-size: 0.8em
            font-family: 'Oswald', sans-serif
            margin-right: 10px
        #kontaktPunkte
            flex: 1 0 0
            display: flex
            justify-content: flex-start
            align-items: center
            height: 100%
            font-size: 1em
            @media (max-width: 600px)
                display: none
            a
                height: 100%
                display: flex
                align-items: center
                img
                    margin-right: 10px
            span
                color: white
                font-size: 0.875em
            @media (max-width: 1200px)
                padding: 0 0 0 20px
            @media (max-width: 1100px)
                flex: 2 0 0
            @media (max-width: 900px)
                flex: 1 0 0
                .mobil
                    display: none
            @media (max-width: 550px)
                flex: 2 0 0
        #mitgliederLogin, #mitgliederLogout
            justify-content: flex-end 
            height: 100%
            display: flex
            align-items: center
            margin: 0  
            transition: all 300ms ease
            font-size: 0.8em
            color: white
            cursor: pointer
            &:hover
                opacity: 0.8      
            @media (max-width: 1200px)
                padding: 0 20px 0 0
            @media (max-width: 900px)
                padding: 0
                flex: 3 0 0
            @media (max-width: 765px)
                
                flex: 2 0 0
            @media (max-width: 550px)
                flex: 1 0 0
            img 
                margin-right: 10px
        #mitgliederLoginText
            margin-top: 6px!important



// Menü

nav
    width: 100%
    padding: 10px 20px
    background: white
    height: 110px
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.2)
    text-transform: uppercase
    z-index: 9
    position: fixed
    margin-top: 30px
    top: 0
    z-index: 9999999999999999999999999999999
    display: flex
    align-items: center

    @media (max-width: 1280px)
        padding: 0
        height: 85px
    .navigation
        max-width: 1250px
        width: 100%
        margin: 0 auto
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: 20px
        z-index: 0
        #logo
            padding: 10px 20px
            img 
                max-width: 150px
                @media (max-width: 1250px)
                    max-width: 100px
            p
                font-size: 0.625em
                font-family: 'Oswald', sans-serif
                @media (max-width: 1250px)
                    font-size: 0.625em
        #burger
            display: none
        #menu
            display: flex
            align-items: center
            transition: all 300ms ease
            .arrow
                display: none
            li
                display: block
                margin: 0
                a
                    display: block
                    padding: 10px 20px
                    @media (max-width: 1250px)
                        font-size: 0.8em
                    &:hover 
                        color: $orange
                .submenu
                    opacity: 0
                    visibility: hidden
                    transform: translateY(40px)
                    transition: all 200ms ease
                    pointer-events: none
                    position: absolute
                    left: 0
                    top: 100%
                    li
                        margin: 0
                        padding: 0
                        min-width: 150%
                        a
                            display: block
                            padding: 10px 20px
                            background: $orange
                            color: white
                            text-align: left
                            left: 0
                            border: 1px solid transparent
                            &:hover
                                border-bottom: 1px solid darken($orange, 5%)
                                border-top: 1px solid darken($orange, 5%)
                                background: lighten($orange, 15%)
                        &:nth-child(even)
                            a
                                background: lighten($orange, 5%)
                                &:hover
                                    background: lighten($orange, 15%)
                &:hover
                    .submenu
                        opacity: 1
                        visibility: visible
                        pointer-events: all
                        transform: translateY(0px)
                        
        @media (max-width: 1090px)
            #menu
                opacity: 0
                right: 0
                padding: 0
                position: absolute
                top: 77px
                transform: translateX(100vw)
                width: 100%
                height: calc(100vh - 70px)
                background: $orange
                display: block!important
                visibility: hidden
                overflow-y: auto
                padding-bottom: 150px
                z-index: -1
                .arrow
                    display: inline-block
                    color: white
                    font-size: 2em
                    line-height: 0
                    position: absolute
                    right: 20px
                    top: 50%
                    transform: translate(0,-50%) rotate(90deg)
                li
                    a
                        color: white
                        text-align: left
                        padding: 20px
                        width: 100%
                        border-top: 1px solid darken($orange, 5%)
                        font-size: 1em
                       
                        //background: $grau
                        &:hover
                            background: lighten($orange, 7%) 
                            color: white
                    .submenu   
                        position: relative!important
                        display: block
                        opacity: 1
                        visibility: visible
                        right: 0
                        top: 0
                        transform: none
                        li
                            min-width: 100%!important
                            padding: 0
                            a
                               
                                background: #ea8700
                                border-bottom: 1px solid darken($orange, 5%)
                                color: white
                                padding: 10px 25px
                                pointer-events: all
                                &:hover
                                    padding: 10px 20px!important
                                    background: lighten($orange, 7%)
                            &:nth-child(even)
                                a
                                    background: lighten($grau, 50%)
                                    background: darken($orange, 5%)
                                    background: #ea8700
                            &:last-child
                                a
                                    border-bottom: none
                &.active
                    transform: translateX(0)
                    visibility: visible
                    opacity: 1



            #burger
                display: block!important
                width: 24px
                height: 24px
                cursor: pointer
                position: absolute
                right: 20px
                top: 50%
                transform: translateY(-50%)
                z-index: 999999999999999999
                span
                    width: 24px
                    height: 2px
                    background: $grau
                    display: block
                    border-radius: 4px
                    position: absolute
                    left: 0
                    top: 50%
                    transform: translateY(-50%)
                    &:before
                        content: ""
                        position: absolute
                        width: 100%
                        height: 100%
                        background: inherit
                        top: -7px
                    &:after
                        content: ""
                        position: absolute
                        width: 100%
                        height: 100%
                        background: inherit
                        top: 7px
                &:hover
                    span
                        background: lighten($base-font-color, 40%)
                &.active
                    span
                        transform: rotate(45deg)
                        &:before
                            width: 0
                            top: 0
                        &:after
                            transform: rotate(-90deg)
                            top: 0
            
// Sticky

#sticky
    background: $grau
    position: fixed
    right: 0
    top: 300px
    z-index: 99
    padding: 10px 5px
    text-align: center
    transition: all 1s ease
    &.out
        right: -100px

    
    a
        p 
            
            color: white
            font-family: 'Oswald', sans-serif
            font-size: 0.6em
            text-transform: uppercase
            transition: all 300ms ease
            font-weight: 400
            max-width: 50px
            margin-bottom: 10px
        .icon
            width: 40px
            height: 40px
            background: #fff
            border-radius: 4px
            display: flex
            justify-content: center
            align-items: center
            margin: 0 auto 5px
            transition: all 300ms ease
            img
                transition: all 300ms ease
                //width: 40px
        &:last-child
            p
                margin-bottom: 0
        &:hover
            p
                opacity: 0.6
            img
                transform: scale(1.1)

    @media (max-width: 1250px)
        bottom: 0
        width: 100%
        margin: 0 auto
        display: flex
        height: auto
        top: auto
        padding-bottom: 10px
        justify-content: center
        left: 50%
        right: auto
        transform: translate(-50%)
        border-radius: 4px 4px 0 0
        max-width: 260px
        &.out
            right: auto
            bottom: -100px
        a
            flex: 1 0 0
            text-align: center
            width: 85px
            p
                width: 100%
                max-width: none
                margin-bottom: 0
