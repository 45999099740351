.beratung 
	// h5
	//	 margin: 0.5rem 0 0.5rem
	//	 display: block
	.middle-row
		p
			padding-bottom: 20px
			b
				font-size: 17px
	b
		padding-bottom: 0.5rem
		padding-top: 0.5rem
		display: block
	.hellgrau
		background: rgba(206,206,206,0.2)
		flex-wrap: wrap-reverse
	.weiss2
		padding: 30px 0
		margin-bottom: 50px
		
	.hellgrau
		padding: 30px
		margin-bottom: 50px
	.weiss
		margin-bottom: 50px
		.col-1
			margin-right: 20px  
			min-width: 300px
			img 
				margin-top: 10px
			@media (max-width: 700px)
				min-width: 100%
				margin-right: 0px  
		.col-2
			margin-right: 20px
			min-width: 300px
		
	.soft-row
		
		.col-1
			img
				width: 100%
		.col-2
			padding-right: 20px
	.hellgrau
		.col-2
			padding-right: 0
			padding-left: 20px
				
			//margin-right: 20px
			 
		