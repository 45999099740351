
@import fontimport

*
	font-family: 'Oswald', sans-serif
	font-size: 17px
	color: $grau
	@media (max-width: 900px)
		font-size: 15px

a
	color: $grau
	font-size: inherit
	font-family: inherit
	font-weight: bold
	transition: all 300ms ease
	&:hover
		color: $orange
b 
	font-size: inherit	
	font-weight: 400
	color: $grau
p , li
	color: $grau
	font-size: 0.825em
	font-family: 'Open Sans', sans-serif

em 
	color: $grau
	font-family: 'Open Sans', sans-serif

h1, h2
	font-size: 3em
	margin-bottom: 10px
	text-transform: uppercase
	color: $orange
	font-weight: 400
	@media (max-width: 900px)
		font-size: 2em
h3
	font-size: 2em
	margin-bottom: 10px
	text-transform: uppercase
	color: $grau
	font-weight: 400
	@media (max-width: 900px)
		font-size: 1.5em
h4
	font-size: 1.8em
	margin-bottom: 10px
	color: white
	text-transform: uppercase
	@media (max-width: 900px)
		font-size: 1.4em
h5
	font-size: 1.4em
	color: $grau
	font-weight: 400
	@media (max-width: 900px)
		font-size: 1.2em

.unterseiten
	h2
		font-size: 2em
		margin-bottom: 10px
		text-transform: uppercase
		color: $orange
		font-weight: 400
		text-align: center
		@media (max-width: 900px)
			font-size: 1.7em
	h3
		text-align: center
		font-size: 1.3em
		margin-bottom: 10px
		text-transform: uppercase
		color: $grau
		font-weight: 400
		@media (max-width: 900px)
			font-size: 1.2em



