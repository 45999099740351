.container
    max-width: 1200px
    margin: 0 auto
    @media (max-width: 1200px)
        padding: 0px
        > div, > section
            padding-left: 40px
            padding-right: 40px
    @media (max-width: 600px)
        padding: 0px
        > div, > section
            padding-left: 20px
            padding-right: 20px
section 
    margin-bottom: 100px
.row
    padding: 30px
    display: block
.soft-row
    display: flex
    flex-wrap: wrap
.col-1
    flex: 1 0 0 
.col-2
    flex: 2 0 0 
.col-3
    flex: 3 0 0 
.col-4
    flex: 4 0 0 
.col-5
    flex: 5 0 0 
.col-6
    flex: 6 0 0 

.einleitung
    max-width: 900px
    margin: 0 auto
    margin-bottom: 30px
    text-align: center
    @media (max-width: 740px)
        margin-bottom: 0



.border 
    border-left: 5px solid $orange 
    padding: 10px 10px 10px 20px
    text-align: left
.box 
    overflow: hidden
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    min-height: 320px
    position: relative
    cursor: pointer
    transition: all 700ms ease
    .bildtitel
        transition: all 300ms ease
        padding: 20px
        position: absolute
        //transform: rotate(-3deg) translate(-50%,77%)
        bottom: 0
        position: absolute
        width: 100%
        left: 0
        min-height: 0
        display: flex!important
        flex-direction: column
        justify-content: center
        align-items: center
        &:before
            transition: all 300ms ease
            content: ""
            width: 120%
            height: 150%
            background: rgba($orange, 0.9)
            position: absolute
            left: 0
            top: 0
            transform: rotate(-3deg)
        h4
            font-size: 1.3em
            //top: -100px
            letter-spacing: 0em
            line-height: 1
            margin-bottom: 0
            @media (max-width: 740px)
                font-size: 1em
                letter-spacing: 0

        p,h4
            color: white
            //transform: rotate(3deg)
            opacity: 1
            text-align: center
        strong
            color: white
            font-family: 'Open Sans', sans-serif
            font-size: 0.825em
            max-width: 300px
            margin: 0 auto
            //display: block
            text-align: center
        p, strong
            display: none
    &:hover
        .bildtitel
            align-items: center
            //transform: rotate(0deg) translate(-50%,0)
            width: 100%
            min-height: 100%
            &:before
                transform: rotate(0)
            h4
                margin-bottom: 10px
            
            
            p,h4
                transform: rotate(0deg)
                top: 0
                max-width: 100%
            p, strong
                display: block
                max-width: 100%
          


.button
    background: white
    border: 1px solid white
    color: $orange
    text-transform: uppercase
    padding: 5px 20px
    cursor: pointer
    display: inline-block
    transition: all 300ms ease
    &:hover
        background: $orange
        color: white
        border: 1px solid white
.buttonOrange
    transition: all 300ms ease
    background: $orange
    border: 1px solid $orange
    color: white
    text-transform: uppercase
    padding: 5px 20px
    cursor: pointer
    width: max-content
    margin: 0 auto
    display: inline-block
    // border-radius: 50px
    &:hover
        background: white
        color: $orange
        border: 1px solid $orange
.hintergrund
    background: white 
    padding-top: 50px

.toggle
  width: 100%
  dt
    background: rgba(206,206,206,0.5)
    padding: 5px 15px 5px 15px
    color: $grau
    transition: all 300ms ease
    cursor: pointer
    position: relative


    &:before
      content: '\25BE'
      color: $grau
      position: absolute
      margin-right: 5px
      transition: all 300ms ease
      right: 15px
      font-weight: 400
      top: 3px
    &:hover
      background: rgba(206,206,206,0.7)
    &~dd
      border: 2px solid rgba(206,206,206,0.7)
      border-top: 0px solid transparent

    &.active
      border-radius: 4px 4px 0 0
      border-bottom: 1px solid rgba(206,206,206,0.5)
      background: rgba(206,206,206,0.5)
      &:before
        transform: rotate(-180deg)
        color: $grau
        margin-top: 5px
  dd
    border-radius: 0 0 4px 4px
    margin-bottom: 15px
    height: 0
    overflow: hidden
    transition: all 300ms ease
    color: $grau
    border: 2px solid  rgba(206,206,206,0.5)
    border-top: 0px solid transparent
    padding: 0 20px
    &.active
        height: auto
        padding: 20px
    strong
      font-size: 1.1em
ul
    li
      list-style: disc
      margin-left: 20px
      color: $grau
      font-family: 'Open Sans', sans-serif
.slider 
    width: 90%
    align-items: center
    margin: 0 auto
    .logos  
        padding: 20px
        margin: auto
        img 
            text-align: center
            margin: 0 auto

        
    .slick-next, .slick-prev
        top: 200px
        &:before
            color: $orange
    .slick-prev
        left: -15px
.target
    position: absolute
    left: 0
    top: -150px

.spinner
    width: 200px
    position: relative
    left: 50%
    top: 0
    text-align: center
    //border-radius: 50%
    transform: translate(-50%)
    margin-bottom: 40px
    background: #fff
    z-index: 0
    display: flex
    justify-content: center
    align-items: center
    p
        position: relative
        z-index: 100
        font-size: 0.925em
        text-align: center
        line-height: 1.2
        max-width: 300px
        color: $orange
    span
        width: 13px
        height: 13px
        position: absolute
        bottom: -20px
        left: 50%
        background: $orange
        border-radius: 50%
        transform: translate(-50%) scale(1)
        opacity: 0
        animation: dots2 2s cubic-bezier(.33,.4,.7,1.43) infinite forwards
        transform-origin: center
        &:first-of-type
            left: 35%
            animation: dots1 2s  cubic-bezier(.33,.4,.7,1.43)  infinite forwards
        &:last-of-type
            animation: dots3 2s  cubic-bezier(.33,.4,.7,1.43)  infinite forwards
            left: 65%
    
    
@keyframes dots1
    0%, 20%
        opacity: 0
        transform: translate(-50%) scale(0)
    30%, 100%
        opacity: 1
        transform: translate(-50%) scale(1)

@keyframes dots2
    0%, 40%
        opacity: 0
        transform: translate(-50%) scale(0)
    50%, 100%
        opacity: 1
        transform: translate(-50%) scale(1)

@keyframes dots3
    0%, 60%
        opacity: 0
        transform: translate(-50%) scale(0)
    70%, 100%
        opacity: 1
        transform: translate(-50%) scale(1)


.middle
    display: flex
    flex-direction: column
    justify-content: center
.middle-row
    display: flex
    align-items: center

.order1
    order: 1
.order2
    order: 2

@media (max-width: 740px)
    .mobile-block
        flex-direction: column
        display: block
    .order1
        order: 1
        margin-left: 0!important
        margin-right: 0!important
        padding-right: 0!important
        padding-left: 0!important
        margin-bottom: 20px
    .order2
        margin-left: 0!important
        margin-right: 0!important
        padding-right: 0!important
        padding-left: 0!important
        order: 2

.bullets
    width: 100%
    position: absolute
    bottom: 0
    left: 0
    display: flex
    justify-content: center
    z-index: 9999
    .bullet
        width: 12px
        height: 12px
        border-radius: 50%
        border: 1px solid white
        display: block
        margin: 20px 5px
        background: transparent
        cursor: pointer
        &.active
            background: #fff
    @media (max-width: 740px)
        width: 40px
        height: 100%
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: center
        flex-direction: column
        .bullet
            margin: 5px
       

// Versteckte Inhalte
.hiddenContent
    display: none
    margin: 10px 0
.user
    .hiddenContent
        display: block

.centered--header
    width: 100%
    max-width: 800px
    text-align: center
    margin: 0 auto 3rem
    h1
        font-size: 2rem 
        margin-bottom: 2rem
    h3
        font-size: 1.2rem
        text-transform: none