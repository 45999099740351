#jahresplan--content
    .jahresplan--element
        display: flex
        align-items: center
        //flex-wrap: wrap
        margin-bottom: 1rem
        background: #fcfcfc
        border: 1px solid lighten($grau, 45%)
        &-image
            height: 250px
        &-content
            padding: 1rem 2rem
            h3
                font-size: 1.4rem
            a
                margin-top: 1rem
    @media (max-width: 860px)
        display: flex
        flex-wrap: wrap
        .jahresplan--element
            display: block
            flex: 1 0 45%
            max-width: calc(50% - 1rem)
            &:nth-child(odd)
                margin-right: 1rem
            &-image
                height: auto
                width: 100%
    @media (max-width: 660px)
        display: block
        .jahresplan--element
            display: block
            flex: 1 0 45%
            max-width: 100%
            &:nth-child(odd)
                margin-right: 0
            &-image
                height: auto
                width: 100%
    .jahresplan--tabs
        display: flex
        flex-wrap: no-wrap
        width: 100%
        text-align: center

        .jahresplan--tab-link
            cursor: pointer
            background: lighten($orange, 15%)
            flex: 1 0 0
            display: flex
            align-items: center
            justify-content: center
            padding: 0 0.2rem

            &:nth-child(even)
                background: lighten($orange, 10%)
            &.active
                background: $orange
            h3
                color: white
                font-size: 1rem
                margin: 0.5rem 0
        @media (max-width: 760px)
            flex-wrap: wrap
            position: relative
            width: 100%
            height: 40px
            z-index: 10
            &.active
                .jahresplan--tab-link
                    display: flex
            &:after
                content: "▼"
                color: white
                line-height: 40px
                position: absolute
                width: 40px
                height: 40px
                right: 0
                top: 0
                background: darken($orange, 10%)
            //overflow: hidden
            .jahresplan--tab-link
                flex: 1 0 calc(100% - 40px)
                position: relative
                align-items: center
                left: 0
                top: 0
                max-width: calc(100% - 40px)
                height: 40px
                display: none
                h3
                    margin: 0
                    padding: 0
                &.active
                    display: flex
    .jahresplan--tab-content
        display: none
        position: relative
        &.active
            display: block
            position: relative
        .jahresplan--tab-image
            width: 100%
        .buttonOrange
            position: absolute
            left: 2rem
            bottom: 2rem