#homepage
    .einleitung
        max-width: 100%
    h1, h2, h3, p
        text-align: left
    h1 
        padding: 0
        margin-top: 0
        margin-bottom: 30px
        font-size: 2em
    h4 
        font-size: 1.3em
    .orange
        margin-bottom: 40px
        .col-1
            background: $orange
            margin: 20px 10px
            padding: 20px
            min-width: 300px
            &:first-child
                margin: 20px 10px 20px 0
            &:second-child
                margin: 20px 0 20px 10px
            @media (max-width: 709px)
                margin: 10px 0!important
                min-width: 280px
            li 
                color: white
    .mockup
        .img
            background: url("../img/homepage/biedermann_optik_mockup.jpg")
            background-position: center 35%
            background-size: cover
            margin-right: 20px
            min-width: 290px
            min-height: 250px
            margin-bottom: 10px
        .col-2
            min-width: 300px
        @media (max-width: 703px)
            display: block
            .img 
                margin-right: 0
            
