.industriepartner
    margin-top: 80px
    margin-bottom: 80px
    h3
        display: block
        margin: 0 auto
        text-align: center
    // min-height: 100vh
    .industriepartnerImg1
        background-image: url(../img/industriepartner/brillenglas.jpg)!important
    .industriepartnerImg2
        background-image: url(../img/industriepartner/brillenfassung.jpg)!important
    .industriepartnerImg3
        background-image: url(../img/industriepartner/kontaktlinse.jpg)!important
    .bildtitel
        display: block
    p 
        max-width: 600px
        margin: 30px auto
        text-align: center
    .soft-row 
        .col-1 
            p 
                // min-height: 300px
                @media (max-width: 794px)
                    min-height: auto
    .logos
        display: flex
        flex-wrap: wrap
        justify-content: center
        // cursor: pointer
        z-index: 0
        .logo--image
            flex: 1 0 25%
            min-width: 0
            display: flex
            align-items: center            
            max-width: 150px!important
            margin: 10px
            position: relative
            z-index: 0
            opacity: 0.5
            img
                width: 100%
                position: relative
                z-index: 0
            &.active
                opacity: 1
        a.logo--image.sliding
            cursor: pointer
   
    .logo--text
        position: relative
        padding: 20px
        box-shadow: 0 0 10px rgba(black,0.15)
        max-width: 700px
        background: #fff
        min-width: 280px
        z-index: 9999999
        height: auto
        display: none
        min-height: 0
        margin: 20px auto 0 auto
        border-top: 3px solid $orange
        min-height: 0!important
        &.l1
            display: block
        
  