#anfrage
    h1
        margin: 0
        padding: 0
        margin-bottom: 20px
    p
        margin-bottom: 40px
#form
    input:not(.buttonOrange)
        margin-bottom: 10px
        padding: 5px 10px
        font-size: 0.825em
        font-family: 'Open Sans', sans-serif
        outline: none
    textarea
        width: 100%
        font-size: 0.825em
        font-family: 'Open Sans', sans-serif
        display: block
        outline: none
        padding: 5px 10px
        border: none
        background: #f1f1f1
        margin-bottom: 10px
    label
        font-family: 'Open Sans', sans-serif
        line-height: 1
        margin: 0   
        margin-left: 5px
        &.btn-outline
            margin-bottom: 10px
            display: inline-block
    input[type="checkbox"]
        margin-bottom: 0
        margin-right: 0
        display: inline-block
    input[type="submit"]
        appearance: none
    span
        font-family: 'Open Sans', sans-serif
        font-size: inherit

#error
    p
        b
            font-family: 'Open Sans', sans-serif
            color: orangered
            font-weight: bold
